const ACTIONS = {
    LOGIN: 'LOGIN',
    GET_TOKEN: 'GET_TOKEN',
    GET_USER: 'GET_USER',
    GET_ALL_USERS: 'GET_ALL_USERS',
    GET_ALL_STREAMERS_ONLINE: 'GET_ALL_STREAMERS_ONLINE',
    GET_SEARCH: 'GET_SEARCH',
    GET_SEARCH_PAGE: 'GET_SEARCH_PAGE',
    GET_SERVER_INFO: 'GET_SERVER_INFO',

}

export default ACTIONS